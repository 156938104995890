import React from "react";

function DiscordEmbed({ title, message, borderColor }) {
    return (
        <div className="embedWrapper-lXpS3L embedFull-2tM8-- embed-IeVjo6 markup-2BOw-j" style={{
            borderLeft: "4px solid " + borderColor,
            background: "#2f3136",
            borderRadius: "4px"
        }}>
            <div style={{
                padding: ".5rem 1rem 1rem .75rem",
                display: "inline-grid",
                gridTemplateColumns: "auto",
                gridTemplateRows: "auto"
            }}>
                <div style={{
                    marginTop: "8px",
                    fontSize: "1rem",
                    fontWeight: "600",
                    display: "inline-block",
                    gridColumn: "1/1"
                }}>
                    <a className="anchor-3Z-8Bb anchorUnderlineOnHover-2ESHQB embedTitleLink-1Zla9e embedLink-1G1K1D embedTitle-3OXDkz" href="https://www.jenkins.io/" rel="noreferrer noopener" target="_blank" style={{
                        color: "#00b0f4"
                    }}>
                        { title }
                    </a>
                </div>
                <div style={{
                    marginTop: "8px",
                    color: "#dcddde"
                }}>
                    { message }
                </div>
            </div>
        </div>
    );
}

export default React.memo(DiscordEmbed);

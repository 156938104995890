import React from 'react';
import YouTube from "react-youtube";
import Helmet from "../../components/Helmet";

import ProjectDetailsTableRow from "../../components/ProjectDetailsTableRow";
import PlatformsRow from "../../components/PlatformsRow";
import Platform from "../../components/Platform";

import moblerLogo from "../../img/projects/mobler/mobler-logo.png";
import moblerBanner from "../../img/projects/mobler/mobler-banner.png";
import moblerBackground from "../../img/projects/mobler/mobler-background.jpg";

import droppingShapesVideo from "../../img/projects/mobler/videos/MoblerShapesDropping.mp4";
import shapesVideo from "../../img/projects/mobler/videos/MoblerShapes.mp4";
import levelEditorVideo from "../../img/projects/mobler/videos/MoblerLevelEditor.mp4";
import steamAndNintendoSwitchLogos from "../../img/projects/mobler/steam-and-nintendo-switch-logos.svg";

function Mobler() {
    return (
        <React.Fragment>
            <Helmet
                title="Möbler"
                description="Inspired by the arcade best seller Tetris® and created by a team of NHTV students, Möbler gives the well-known old Tetris® formula a big twist, creating a dynamic platform like experience with an incredible amount of gameplay for everyone to enjoy."
                image={moblerBackground}
            />

            <div className="jumbotron jumbotron-fluid" style={{
                backgroundImage: `url(${moblerBanner})`,
                backgroundPosition: "center",
                backgroundSize: "cover"
            }}>
                <div className="container text-center text-white">
                    <img src={moblerLogo} className="img-fluid img-mobler-logo-detail" alt="Möbler Logo"/>
                    <p className="d-block" style={{maxWidth: "750px", marginLeft: "auto", marginRight: "auto"}}>
                        Inspired by the arcade best seller Tetris® and created by a team of NHTV students, Möbler gives
                        the well-known old Tetris® formula a big twist, creating a dynamic platform like experience with
                        an incredible amount of gameplay for everyone to enjoy.
                    </p>
                </div>
            </div>

            <div className="container mt-4 mb-4">

                <PlatformsRow>
                    <Platform steam link="https://store.steampowered.com/app/1033100/Mobler"/>
                    <Platform nintendoSwitch soon/>
                    <Platform xboxone soon/>
                </PlatformsRow>

                <div className="row">
                    <div className="col-md-8 mb-4 mb-md-0">
                        <h2>Trailer</h2>
                        <YouTube videoId="z_4DAsUMNCA" className="embed-responsive-item"
                                 containerClassName="embed-responsive embed-responsive-16by9"/>
                    </div>
                    <div className="col-md-4">
                        <h2>Project Details</h2>
                        <table className="table">
                            <tbody>
                            <ProjectDetailsTableRow title="Website">
                                <a href="https://moblergame.com"
                                   target="_blank" rel="noopener noreferrer">
                                    moblergame.com
                                </a>
                            </ProjectDetailsTableRow>
                            <ProjectDetailsTableRow title="Engine">Unreal Engine 4</ProjectDetailsTableRow>
                            <ProjectDetailsTableRow title="Development Time">8 weeks</ProjectDetailsTableRow>
                            <ProjectDetailsTableRow title="Team Size">9 people</ProjectDetailsTableRow>
                            <ProjectDetailsTableRow title="My Role">Programmer</ProjectDetailsTableRow>
                            <ProjectDetailsTableRow title="Programming Languages">C++, Blueprints,
                                PHP</ProjectDetailsTableRow>
                            <ProjectDetailsTableRow title="Target Platforms">
                                Windows
                                <br/>
                                Nintendo Switch
                                <br/>
                                XBOX ONE
                            </ProjectDetailsTableRow>
                            </tbody>
                        </table>
                    </div>
                </div>

                <h2>My contributions</h2>

                <div className="row mb-4">
                    <div className="col-md-6">
                        <h4>Sequence Selection System</h4>
                        <p>
                            The biggest system I worked on in this project, is the Sequence Selection System. This
                            is a system in the game that will select which sequence of shapes will be dropped next.
                            The system procedurally generates the order in which the sequences are dropped, making
                            sure sequences aren't instantly repeated and taking difficulty of those sequences into
                            account, increasingly making it more difficult for the player.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <video style={{maxWidth: "100%"}} loop autoPlay playsInline>
                            <source src={droppingShapesVideo} type="video/mp4"/>
                        </video>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-6">
                        <h4>Shapes</h4>
                        <p>
                            I've also worked on the individual shapes that fall from the sky, which are loaded from
                            a JSON config file that can be modified by a designer. The config file also includes
                            which 3D models are used for the visual part of the shape. Multiple 3D models can be
                            defined per shape, adding variation to the game. When no 3D models are available, the
                            system will automatically generate placeholder cubes to allow designers to experiment
                            and play-test with new shapes.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <video style={{maxWidth: "100%"}} loop autoPlay playsInline>
                            <source src={shapesVideo} type="video/mp4"/>
                        </video>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-6">
                        <h4>Level Editor</h4>
                        <p>
                            The sequences of shapes that drop while you play are stored in a JSON config that gets
                            shipped with the game. Editing the JSON config manually isn't a very efficient way to
                            work, so I created a Level Editor in a separate Unreal Engine project which they can use
                            to visually edit the sequences.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <video style={{maxWidth: "100%"}} loop autoPlay playsInline>
                            <source src={levelEditorVideo} type="video/mp4"/>
                        </video>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-6">
                        <h4>Steam and Nintendo Switch</h4>
                        <p>
                            I've been responsible for releasing the game on Steam together with our producer. We did
                            this with no prior experience releasing on this platform. Additionally, I am currently
                            working on releasing the game on Nintendo Switch and have already received access to the
                            necessary tools from Nintendo.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <img src={steamAndNintendoSwitchLogos} className="img-fluid" alt="Steam and Nintendo Switch"/>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
}

export default React.memo(Mobler);

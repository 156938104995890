import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { stringify } from "qs";
import axios from "axios";
import {
    BrowserRouter
} from "react-router-dom";

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
    apiKey: '5097d17d93b5d388b709d17778918bb9',
    plugins: [new BugsnagPluginReact()]
})

axios.defaults.transformRequest = [function (data) {
    data = stringify(data);
    return data;
}];

axios.defaults.baseURL = "https://api.zandorsmith.com/v1";
axios.defaults.withCredentials = true;

const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

let application = (
    <ErrorBoundary>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </ErrorBoundary>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(application, rootElement);
} else {
    ReactDOM.render(application, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import {
    Link
} from "react-router-dom";

function AboutMeJumbotron() {
    return (
        <div className="jumbotron jumbotron-fluid jumbotron-clear bg-pattern-light">
            <div className="container">
                <div className="card card-glass">
                    <div className="card-body">
                        <h1>About me</h1>
                        <p style={ { fontSize: "1.2rem" } }>
                            Hi, my name is Zandor Smith and I am a software programmer, currently focussing on <b>Games</b>. I
                            have been developing software for over 6 years. During this time span, I've been working with a
                            varying range of technologies targeted at different platforms such as{" "}
                            <b>Windows, PS4 and Xbox One</b> but also mobile with <b>iOS</b>.
                        </p>
                        <p style={ { fontSize: "1.2rem" } }>
                            I am currently studying Game Programming at{" "}
                            <a href="https://buas.nl" target="_blank" rel="noopener noreferrer">
                                Breda University of Applied Sciences (NHTV)
                            </a> since 2017. During my education at BUAS, I've been working on big projects in multi-disciplinary
                            teams, but also on small personal projects. The most prominent projects I've worked on are{" "}
                            <Link to="/paint">P.A.I.N.T.</Link> and <Link to="/mobler">Möbler</Link> which are featured below.
                            In these projects, I've fulfilled my roles ranging from <b>Engine, Tools or Network Programmer</b>{" "}
                            to <b>setting up & managing QA pipelines</b>.
                        </p>
                        <p style={ { fontSize: "1.2rem" } } className="mb-0">
                            I am looking for an internship from <b>February 2021</b>. I live in 's-Gravenzande, Zuid-Holland, The Netherlands.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(AboutMeJumbotron);

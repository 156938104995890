import React from "react";
import Helmet from "../../components/Helmet";

import "./../../scss/old-site.scss";

import appIcon from "./../../img/projects/ios/smithsolarmonitoring/icon.svg";
import appStore from "./../../img/projects/ios/appstore.svg";

import screenshotOverview from "./../../img/projects/ios/smithsolarmonitoring/screenshots/overview.png";
import screenshotInverters from "./../../img/projects/ios/smithsolarmonitoring/screenshots/inverters.png";
import screenshotDailyGraph from "./../../img/projects/ios/smithsolarmonitoring/screenshots/dailygraph.png";
import screenshotWeeklyGraph from "./../../img/projects/ios/smithsolarmonitoring/screenshots/weeklygraph.png";

function SmithSolarMonitoring() {
    return (
        <React.Fragment>
            <Helmet
                title="Smith Solar Monitoring"
                description="This app is a mobile version of the web Smith Solar Monitoring. This app will allow users to monitor their Solar Panel installations in terms of how much power it generated, how much it is outputting currently and will do so using beautiful graphs."
            />

            <div className="jumbotron jumbotron-fluid jumbotron-rotate jumbotron-orange jumbotron-app-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <img className="ios-app-icon" src={appIcon} height="100px" style={{marginTop: "40px"}}
                                 alt="Smith Solar Monitoring App Icon"/>
                            <h1 className="title title-app"><b>Smith Solar Monitoring</b></h1>
                            <p className="text-white" style={{fontSize: "1.25rem"}}>
                                This app is a mobile version of the web Smith Solar Monitoring. This app will allow
                                users to monitor their Solar Panel installations in terms of how much power it
                                generated, how much it is outputting currently and will do so using beautiful
                                graphs.
                                Note that the app is available in Dutch only.
                            </p>
                            <p className="text-white" style={{fontSize: "1.25rem"}}>
                                Available for <b>iPhone</b> and <b>iPad</b>.
                            </p>
                            <br/>
                            <a href="https://apps.apple.com/nl/app/smith-solar-monitor/id1029136856" target="_blank"
                               rel="noreferrer noopener">
                                <img className="appstore" src={appStore} alt="Available on the App Store"/>
                            </a>
                        </div>
                        <div className="col-md-4 hidden-sm hidden-xs">
                            <img src={screenshotOverview} className="img-fluid"
                                 alt="Smith Solar Monitoring screenshot"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="jumbotron jumbotron-fluid" style={{backgroundColor: "transparent"}}>
                <div className="container text-center">
                    <p style={{fontSize: "1.25rem"}}>
                        The monitoring system behind this was created for
                        <a href="https://smithsolar.nl" target="_blank" rel="noreferrer noopener">Smith Solar
                            B.V.</a>{" "}
                        to provide customers of theirs with a solution following the bankruptcy of Enecsys. Enecsys
                        was a manufacturer of Solar micro-inverters that had an accompanying monitoring platform
                        that was shutdown shortly after. Customers were no longer able to monitor their solar
                        panels, so this system was created as a solution.
                    </p>
                    <p style={{fontSize: "1.25rem"}}>
                        The monitoring system, online panel and this accompanying app are created by me.
                    </p>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-4 col-sm-6 mb-5">
                        <img src={screenshotOverview} className="img-fluid mb-3" alt="Overview"/>
                        <h3>Overview</h3>
                        <p>This screen will provide the user the most important metrics at a glance.</p>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-5">
                        <img src={screenshotInverters} className="img-fluid mb-3" alt="Inverters"/>
                        <h3>Inverters</h3>
                        <p>Here, the user can view the power being generated by each inverter individually.</p>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-5">
                        <img src={screenshotDailyGraph} className="img-fluid mb-3" alt="Daily Graphs"/>
                        <h3>Daily Graphs</h3>
                        <p>This graph will provide the user with the exact power output of all the inverters
                            combined throughout the entire day, and days in the past.</p>
                    </div>
                    <div className="col-md-12 col-sm-6 mb-5">
                        <div className="row">
                            <div className="col-md-4 hidden-sm hidden-xs"/>
                            <div className="col-md-4">
                                <img src={screenshotWeeklyGraph} className="img-fluid mb-3" alt="Weekly Graphs"/>
                                <h3>Weekly, Monthly and Annual Graphs</h3>
                                <p>This graph will give the user insights into the generated power of their entire
                                    solar installation over time at different detail levels.</p>
                            </div>
                            <div className="col-md-4 hidden-sm hidden-xs"/>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(SmithSolarMonitoring);

import React, {
    useLayoutEffect,
    useRef,
    useState
} from "react";

function Footer() {
    const footerRef = useRef();
    const [height, setHeight] = useState(0);

    useLayoutEffect(() => {
        if (footerRef.current) {
            setHeight(footerRef.current.offsetHeight);
        }
    }, []);

    return (
        <React.Fragment>
            <div style={{ height: height + "px" }}/>
            <div ref={ footerRef } className="jumbotron jumbotron-fluid jumbotron-footer">
                <div className="container">
                    <p>
                        Copyright © 2016 - { new Date().getFullYear() } Zandor Smith. All rights reserved.
                    </p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(Footer);

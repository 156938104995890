import React from 'react';
import YouTube from "react-youtube";
import {
    Link
} from "react-router-dom";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import "../../scss/projects.scss";

import moblerLogo from "../../img/projects/mobler/mobler-logo.png";
import paintLogo from "../../img/projects/paint/paint-logo.png";
import reptoadsLogo from "../../img/projects/reptoads/reptoads-logo-small.png";

import lumberjackedLogo from "../../img/projects/lumberjacked/lumberjacked-logo.png";
import smithSolarMonitoringAppIcon from "../../img/projects/ios/smithsolarmonitoring/icon.svg";
import iswLichtkrantAppIcon from "../../img/projects/ios/isw-lichtkrant/icon.svg";

import smithSolarMonitoringAppScreenshot from "../../img/projects/ios/smithsolarmonitoring/screenshots/overview.png";
import iswLichtkrantAppScreenshot from "../../img/projects/ios/isw-lichtkrant/screenshots/bovenbouw.png";

function ProjectsJumbotron() {
    return (
        <div className="jumbotron jumbotron-fluid jumbotron-clear pb-0 bg-pattern-dark" style={{ borderTop: "#007BFF 5px solid" }}>
            <div className="container text-white">
                <h1>Projects</h1>
            </div>
            <div className="jumbotron-paint">
                <div className="jumbotron jumbotron-fluid jumbotron-opacity jumbotron-projects">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 mb-3">
                                <img src={ paintLogo } className="img-fluid img-paint-logo" alt="P.A.I.N.T."/>
                                <div className="mt-2 mb-1">
                                    <span className="badge badge-primary mr-2">Unreal Engine 4</span>
                                    <span className="badge badge-primary mr-2">PC & PS4</span>
                                    <span className="badge badge-primary mr-2">25 people</span>
                                    <span className="badge badge-primary mr-2">40 weeks</span>
                                </div>
                                <p>
                                    In this cooperative third-person shooter, you take control of the P.U.N.K.’s. Rebels
                                    that shoot their way through an evil factory with high-powered paint blasters.
                                    Sabotage the tyrannical corporation and fight your way through their factory to
                                    achieve the highest chaos score! A team consisting of me and 24 other people has
                                    worked on this game for an entire school year as part of our education.
                                </p>
                                <Link to="/paint" className="btn btn-primary">Learn more</Link>
                                <a href="https://store.steampowered.com/app/1175890/PAINT" className="btn btn-secondary ml-2" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-steam mr-2"/>
                                    Play on Steam
                                </a>
                            </div>
                            <div className="col-md-5">
                                <YouTube videoId="N9NEkRFDwcI" className="embed-responsive-item" containerClassName="embed-responsive embed-responsive-16by9"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="jumbotron-mobler">
                <div className="jumbotron jumbotron-fluid jumbotron-opacity jumbotron-projects">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 mb-3">
                                <img src={ moblerLogo } className="img-fluid img-mobler-logo" alt="Möbler"/>
                                <h2 className="sr-only">Möbler</h2>
                                <div className="mt-2 mb-1">
                                    <span className="badge badge-primary mr-2">Unreal Engine 4</span>
                                    <span className="badge badge-primary mr-2">PC & Nintendo Switch</span>
                                    <span className="badge badge-primary mr-2">9 people</span>
                                    <span className="badge badge-primary mr-2">8 weeks</span>
                                </div>
                                <p>
                                    Möbler is a spin-off of the popular arcade game Tetris®, created by a team
                                    consisting of me and 8 other people. We created this game in 8 weeks during our
                                    first year at Breda University of Applied Sciences. After the school block ended, we
                                    continued development on the game and now it has been released on Steam! The game is
                                    created in Unreal Engine.
                                </p>
                                <Link to="/mobler" className="btn btn-primary">Learn more</Link>
                                <a href="https://store.steampowered.com/app/1033100/Mobler" className="btn btn-secondary ml-2" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-steam mr-2"/>
                                    Play on Steam
                                </a>
                            </div>
                            <div className="col-md-5">
                                <YouTube videoId="z_4DAsUMNCA" className="embed-responsive-item" containerClassName="embed-responsive embed-responsive-16by9"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="jumbotron-reptoads">
                <div className="jumbotron jumbotron-fluid jumbotron-opacity jumbotron-projects">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 mb-3">
                                <img src={ reptoadsLogo } className="img-fluid img-reptoads-logo" alt="Reptoads - Tomorrow Engine"/>
                                <h2 className="sr-only">Reptoads - "Tomorrow Engine"</h2>
                                <div className="mt-2 mb-1">
                                    <span className="badge badge-primary mr-2">Custom C++ Engine</span>
                                    <span className="badge badge-primary mr-2">PC & PS4</span>
                                    <span className="badge badge-primary mr-2">11 programmers</span>
                                    <span className="badge badge-primary mr-2">8 weeks</span>
                                </div>
                                <p>
                                    Tomorrow Engine is a cross platform C++ game framework which allows the creation
                                    of online multiplayer deterministic linear card games. The game we created with this
                                    is called Reptoads. In the game, the player has to fight the opponent and the 3
                                    monsters in order to win the game. The framework supported PlayStation 4 and
                                    Windows 10.
                                </p>
                                <button className="btn btn-primary" disabled>Learn more (Coming soon)</button>
                                <a href="https://github.com/tomorrowengine/Reptoads" className="btn btn-secondary ml-2" target="_blank" rel="noreferrer noopener">
                                    <i className="fab fa-github mr-2"/>
                                    GitHub
                                </a>
                            </div>
                            <div className="col-md-5">
                                <YouTube videoId="7LqEddob8ao" className="embed-responsive-item" containerClassName="embed-responsive embed-responsive-16by9"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container text-white mt-5">
                <h1>Additional projects</h1>
            </div>
            <div className="jumbotron-lumberjacked">
                <div className="jumbotron jumbotron-fluid jumbotron-opacity jumbotron-projects">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 mb-3">
                                <img src={ lumberjackedLogo } className="img-fluid img-lumberjacked-logo" alt="Lumberjacked"/>
                                <h2 className="sr-only">Lumberjacked</h2>
                                <div className="mt-2 mb-1">
                                    <span className="badge badge-primary mr-2">Unreal Engine 4</span>
                                    <span className="badge badge-primary mr-2">PC</span>
                                </div>
                                <p>
                                    Lumberjacked is a game, made by a team I didn't originally was a part of. A group of
                                    students from Breda University of Applied Sciences are setting up an initiative to
                                    continue development on Möbler and Lumberjacked. This includes getting Lumberjacked
                                    released on Steam. This is currently in the works.
                                </p>
                                <a href="https://igad.itch.io/lumberjacked" className="btn btn-secondary" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-itch-io mr-2"/>
                                    Play on Itch
                                </a>
                            </div>
                            <div className="col-md-5">
                                <YouTube videoId="Nvy8hr8nxQE" className="embed-responsive-item" containerClassName="embed-responsive embed-responsive-16by9"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="jumbotron-ios" style={{ backgroundColor: "#25252d" }}>
                <div className="jumbotron jumbotron-fluid jumbotron-opacity-65 jumbotron-projects">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <h2>iOS apps</h2>
                                <div className="mt-2 mb-1">
                                    <span className="badge badge-primary mr-2">Xcode</span>
                                    <span className="badge badge-primary mr-2">iOS & watchOS</span>
                                    <span className="badge badge-primary mr-2">5+ years experience</span>
                                    <span className="badge badge-primary mr-2">Personal & Professional</span>
                                </div>
                                <p>
                                    Since 2015, I write my own iOS apps in my free time as personal projects and for
                                    customers. I've written multiple iOS apps, some of which also have accompanying
                                    watchOS apps bundled.
                                </p>
                                <div>
                                    <OverlayTrigger overlay={
                                        <Tooltip id={`tooltip`}>Smith Solar Monitoring</Tooltip>
                                    }>
                                        <Link to="/ios/smithsolarmonitoring" className="d-inline-block mr-3" style={{ width: "100px" }}>
                                            <img className="ios-app-icon" src={ smithSolarMonitoringAppIcon } style={{ width: "100px" }} alt="Smith Solar Monitoring"/>
                                            <div className="text-center text-white mt-2" style={{ width: "100px" }}>
                                                Smith Solar Monitoring
                                            </div>
                                        </Link>
                                    </OverlayTrigger>
                                    <OverlayTrigger overlay={
                                        <Tooltip id={`tooltip`}>ISW Lichtkrant</Tooltip>
                                    }>
                                        <Link to="/ios/iswlichtkrant" className="d-inline-block mr-3" style={{ width: "100px" }}>
                                            <img className="ios-app-icon" src={ iswLichtkrantAppIcon } style={{ width: "100px" }} alt="ISW Lichtkrant"/>
                                            <div className="text-center text-white mt-2" style={{ width: "100px" }}>
                                                ISW Lichtkrant
                                            </div>
                                        </Link>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div className="col-md-2half d-none d-md-block">
                                <img src={ smithSolarMonitoringAppScreenshot } className="img-fluid" alt="Smith Solar Monitoring Screenshot"/>
                            </div>
                            <div className="col-md-2half d-none d-md-block">
                                <img src={ iswLichtkrantAppScreenshot } className="img-fluid" alt="ISW Lichtkrant Screenshot"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(ProjectsJumbotron);

import React from "react";
import MetaTags from "react-meta-tags";

function Helmet({
    title = undefined,
    siteName = "Zandor Smith",
    description = "Software Developer",
    slogan = "Software Developer",
    image = undefined
}) {
    let pageTitle = siteName + " - " + slogan;
    if(title !== undefined) {
        pageTitle = title + " - " + siteName;
    }
    return (
        <MetaTags>
            <title>{ pageTitle }</title>
            <meta name="title" content={ pageTitle } />
            <meta property="og:title" content={ pageTitle } />
            <meta name="twitter:title" content={ pageTitle } />
            <meta name="description" content={ description } />
            <meta property="og:description" content={ description } />
            <meta name="twitter:description" content={ description } />
            <meta name="summary" content={ description } />
            <meta property="og:image" content={ image } />
            <meta name="twitter:image" content={ image } />
        </MetaTags>
    )
}

export default React.memo(Helmet);

import React from 'react';
import {
    Link
} from "react-router-dom";
import Helmet from "../components/Helmet";

function Page404() {
    return (
        <React.Fragment>
            <Helmet
                title="404 Not Found"
                description=""
            />

            <div className="jumbotron jumbotron-fluid">
                <div className="container text-center">
                    <h1>404 Not Found</h1>
                </div>
            </div>

            <div className="container mt-4 h3 text-center">
                <p>This page doesn't exist. <Link to="/">Go back to home</Link>.</p>
            </div>
        </React.Fragment>
    );
}

export default React.memo(Page404);

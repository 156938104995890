import React from 'react';
import YouTube from "react-youtube";
import Helmet from "../../components/Helmet";

import ProjectDetailsTableRow from "../../components/ProjectDetailsTableRow";
import DiscordEmbed from "../../components/DiscordEmbed";
import PlatformsRow from "../../components/PlatformsRow";
import Platform from "../../components/Platform";

import paintLogo from "../../img/projects/paint/paint-logo.png";
import paintBanner from "../../img/projects/paint/paint-background.jpg";

import sentryLogo from "../../img/projects/paint/images/sentry.svg";

function PAINT() {
    const description = "In this cooperative third-person shooter, you take control of the P.U.N.K.’s. Rebels that shoot their way through an evil factory with high-powered paint blasters. Sabotage the tyrannical corporation and fight your way through their factory to achieve the highest chaos score!";

    return (
        <React.Fragment>
            <Helmet
                title="P.A.I.N.T."
                description={description}
                image={paintBanner}
            />

            <div className="jumbotron jumbotron-fluid" style={{
                backgroundImage: `url(${paintBanner})`,
                backgroundPosition: "center",
                backgroundSize: "cover"
            }}>
                <div className="container text-center text-white">
                    <img src={paintLogo} className="img-fluid img-mobler-logo-detail" alt="P.A.I.N.T. Logo"/>
                    <p className="d-block" style={{maxWidth: "750px", marginLeft: "auto", marginRight: "auto"}}>
                        {description}
                    </p>
                </div>
            </div>

            <div className="container mt-4">

                <PlatformsRow>
                    <Platform steam link="https://store.steampowered.com/app/1175890/PAINT"/>
                    <Platform ps4 support/>
                </PlatformsRow>

                <div className="row">
                    <div className="col-md-8 mb-4 mb-md-0">
                        <h2>Trailer</h2>
                        <YouTube videoId="N9NEkRFDwcI" className="embed-responsive-item"
                                 containerClassName="embed-responsive embed-responsive-16by9"/>
                    </div>
                    <div className="col-md-4">
                        <h2>Project Details</h2>
                        <table className="table">
                            <tbody>
                            <ProjectDetailsTableRow title="Website">
                                <a href="https://paint-game.com"
                                   target="_blank" rel="noopener noreferrer">
                                    paint-game.com
                                </a>
                            </ProjectDetailsTableRow>
                            <ProjectDetailsTableRow title="Engine">Unreal Engine 4</ProjectDetailsTableRow>
                            <ProjectDetailsTableRow title="Development Time">40 weeks</ProjectDetailsTableRow>
                            <ProjectDetailsTableRow title="Team Size">25 people</ProjectDetailsTableRow>
                            <ProjectDetailsTableRow title="My Role">
                                Tech Lead
                                <br/>
                                QA Lead
                                <br/>
                                Programmer
                            </ProjectDetailsTableRow>
                            <ProjectDetailsTableRow title="Programming Languages">C++, Blueprints, C#, Python,
                                Batch</ProjectDetailsTableRow>
                            <ProjectDetailsTableRow title="Target Platforms">
                                Windows
                                <br/>
                                PlayStation 4
                            </ProjectDetailsTableRow>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <h2>My contributions</h2>

                        <h4>Tech Lead</h4>
                        <p>During the second half of the project, I took the role of tech lead. This included some
                            of the following tasks;</p>
                        <ul>
                            <li>Having meetings with the other leads and individual teammembers.</li>
                            <li>Setting goals for every Sprint for the programmers in the team.</li>
                            <li>Giving updates in the form of a presentation to the stakeholders (school teaching
                                staff).
                            </li>
                            <li>Organising Stand downs at the end of the day with the programmers to give each other
                                progress updates.
                            </li>
                            <li>Making sure everyone in the team is happy.</li>
                        </ul>

                        <div className="card mb-4">
                            <div className="card-body">
                                <blockquote className="blockquote mb-0">
                                    <p className="mb-0" style={{fontSize: "1rem"}}>
                                        You have picked up the role as lead programmer very well. Honestly you
                                        are the best tech lead I have come across. You take your role very
                                        seriously and are actively involved in many sections of the project, not
                                        only tech. Your behavior and communication is very professional.
                                    </p>
                                    <footer className="blockquote-footer mt-3">
                                        <b>Arthur Kuylaars</b> - teammember on this project
                                    </footer>
                                </blockquote>
                            </div>
                        </div>

                        <h4>Quality Assurance Lead</h4>
                        <p>Throughout the entirety of the project, I've been responsible for the technical side of
                            Quality Assurance. I've been keeping myself busy setting up our processes such as Helix
                            Swarm Reviews, setting up Continuous Integration using Jenkins, Continuous Delivery to
                            Steam and in general, making sure we keep the build stable as much as possible.</p>
                        <p>Here are some things I've worked on that fall under my role as Quality Assurance Lead;</p>

                        <h5>Continuous Integration</h5>
                        <p>For Continuous Integration, we are using Jenkins, which periodically checks for changes
                            in our Perforce Depot, and if there are any, it triggers a build for the different
                            platforms we support. (Windows and PlayStation 4) This is done for both the Development
                            and Shipping configurations. When a build succeeds or fails, a message is sent in
                            Discord to notify the team about this.</p>

                        <div className="mb-4" style={{maxWidth: "400px", marginLeft: "auto", marginRight: "auto"}}>
                            <DiscordEmbed
                                title="CoopGame-Project-Win64-Shipping (Build 363)"
                                message="Successful build"
                                borderColor="rgb(46, 204, 113)"
                            />
                        </div>

                        <h5>Crash Reporting</h5>
                        <p>I've been responsible for setting up the Unreal Engine Crash Reporter to post crash
                            reports to my Sentry server. This allows us to see when and why the game crashes and
                            aids us in improving the game.</p>

                        <div className="text-center">
                            <img src={sentryLogo} style={{maxWidth: "300px"}} alt="Sentry Logo"/>
                        </div>

                        <h5>Swarm Reviews</h5>
                        <p>I've been responsible for setting up the Swarm Review processes that the team follows.
                            The process is set up in a way that allows us to enforce Continuous Delivery, by always
                            having a stable build ready to deploy to Steam.</p>

                        <h4>Analytics</h4>
                        <p>TODO</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(PAINT);

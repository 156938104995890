import React from "react";
import CookieConsent from "react-cookie-consent";

import "../scss/cookie-consent.scss";

function CookieConsentBanner() {
    return (
        <div className="cookie-consent-container">
            <CookieConsent
                disableStyles={ true }
                containerClasses="cookie-consent-banner"
                contentClasses="cookie-consent-content"
                buttonClasses="btn btn-primary btn-sm cookie-consent-button"
            >
                This website uses cookies to enhance the user experience. <span role="img" aria-label="cookie emoji">🍪</span>
            </CookieConsent>
        </div>
    )
}

export default React.memo(CookieConsentBanner);

import React from 'react';
import {
    Button,
    ButtonGroup,
    ButtonToolbar
} from "react-bootstrap";

import "../../scss/contact.scss";

function SocialButton(props) {
    return (
        <ButtonGroup className="mr-2">
            <Button variant={ props.variant ?? "primary" }
                    href={ props.href }
                    target={ props.href ? "_blank" : ""}
                    rel={ props.href ? "noopener noreferrer" : ""}
            >
                { props.children }
            </Button>
        </ButtonGroup>
    );
}

function ContactJumbotron() {
    return (
        <div className="jumbotron jumbotron-fluid jumbotron-clear bg-pattern-dark" style={{ borderTop: "#007BFF 5px solid" }}>
            <div className="container">
                <div style={{ padding: "100px 0" }}>
                    <div className="card card-glass-dark">
                        <div className="card-body">
                            <h1>Contact me</h1>
                            <p style={ { fontSize: "1.2rem" } }>
                                To contact me, you can mail your inquery to <a href="mailto:info@zsinfo.nl">info@zsinfo.nl</a>.
                            </p>
                            <ButtonToolbar>
                                <SocialButton variant="linkedin" href="https://linkedin.com/in/zandor-smith-3553a5148">
                                    <i className="fab fa-linkedin-in mr-2"/>LinkedIn
                                </SocialButton>
                                <SocialButton variant="stackoverflow" href="https://stackoverflow.com/users/5544222/zandor-smith">
                                    <i className="fab fa-stack-overflow mr-2"/>StackOverflow
                                </SocialButton>
                                <SocialButton variant="github" href="https://github.com/zandor300">
                                    <i className="fab fa-github mr-2"/>GitHub
                                </SocialButton>
                                <SocialButton variant="gitlab" href="https://git.zsinfo.nl/zandor300">
                                    <i className="fab fa-gitlab mr-2"/>GitLab
                                </SocialButton>
                            </ButtonToolbar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(ContactJumbotron);

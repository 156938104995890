import React, {useEffect} from 'react';

import "../../scss/home-carousel.scss";

import moblerImage from "../../img/projects/mobler/mobler-background.jpg";
import paintImage from "../../img/projects/paint/paint-background.jpg";
import reptoadsImage from "../../img/projects/reptoads/reptoads-background.jpg";
import lumberjackedImage from "../../img/projects/lumberjacked/lumberjacked-background.png";
// import iosImage from "./../img/projects/ios/ios-apps-background.png";

import meImage from '../../img/me.jpg';

function TopJumbotron() {
    let scroller = React.createRef();
    useEffect(() => {
        const interval = setInterval(() => {
            if(!scroller)
                return;
            scroller.current.scrollLeft = scroller.current.scrollLeft + 1;
            if(scroller.current.scrollLeft >= 4 * 600) {
                scroller.current.scrollLeft = 0;
                console.log("Scroll")
            }
        }, 40);
        return () => clearInterval(interval);
    }, [scroller]);
    return (
        <div className="jumbotron jumbotron-fluid home-carousel">
            <div className="home-carousel-background" ref={ scroller }>
                <div className="home-carousel-background-scroller">
                    {[...Array(3)].map((value, index) => (
                        <React.Fragment key={ index }>
                            <img src={ moblerImage } alt="Möbler"/>
                            <img src={ paintImage } alt="P.A.I.N.T."/>
                            <img src={ reptoadsImage } alt="Reptoads"/>
                            {/*<img src={ iosImage } alt="iOS apps"/>*/}
                            <img src={ lumberjackedImage } alt="Lumberjacked"/>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className="home-carousel-content">
                <div className="pt-3 pb-3" style={{ backgroundColor: "rgba(0, 0, 0, .5)"}}>
                    <div className="container text-white">
                        <div className="row">
                            <div className="col-4 col-sm-3 col-md-4 col-lg-2">
                                <img src={ meImage } className="img-fluid rounded-pill" alt="Zandor Smith"/>
                            </div>
                            <div className="col-8 col-sm-9 col-md-8 col-lg-10">
                                <h1 className="mt-lg-3 mt-md-3">Zandor Smith</h1>
                                <h4>Software Developer</h4>
                                <p>Looking for Internship from February 2021</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(TopJumbotron);

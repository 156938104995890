import React, { Children, cloneElement, isValidElement, useState } from 'react';
import {
    Link,
    useRouteMatch
} from "react-router-dom";
import {
    Container,
    Nav,
    NavDropdown,
    Navbar as RBNavbar
} from "react-bootstrap";

function NavbarLink({to, title, onClick}) {
    let match = useRouteMatch({
        path: to,
        exact: true
    });
    let className = "nav-item " + (match ? "active" : "");
    return (
        <li className={className}>
            <Link className="nav-link" to={to} onClick={onClick}>{title}</Link>
        </li>
    );
}

function NavbarDropdownLink({to, title, onClick}) {
    let match = useRouteMatch({
        path: to,
        exact: true
    });
    let className = "dropdown-item " + (match ? "active" : "");
    return (
        <Link className={className} to={to} onClick={onClick}>{title}</Link>
    );
}

function NavbarDropdown(props) {
    let [open, setOpen] = useState(false);
    let match = useRouteMatch({
        path: props.active,
        exact: true
    })
    let className = "nav-item dropdown " + (match ? "active" : "");
    const onClick = () => {
        setOpen(false);
        if(props.onClick !== undefined) {
            props.onClick();
        }
    }
    const childrenWithProps = Children.map(props.children, child => {
        if (isValidElement(child)) {
            return cloneElement(child, { onClick: onClick })
        }
        return child;
    });
    return (
        <NavDropdown title={ props.title } className={className} onToggle={ () => setOpen(!open) } show={open} id="basic-nav-dropdown">
            { childrenWithProps }
        </NavDropdown>
    );
}

function Navbar() {
    let [showCollapsed, setShowCollapsed] = useState(false);
    let collapse = () => {
        setShowCollapsed(false);
    }
    return (
        <React.Fragment>
            <RBNavbar bg="light" expand="lg" className="fixed-top navbar-only" expanded={ showCollapsed }>
                <Container>
                    <a className="navbar-brand" href="/">
                        Zandor Smith
                    </a>
                    <RBNavbar.Toggle aria-controls="basic-navbar-nav" onClick={ () => setShowCollapsed(!showCollapsed) } />
                    <RBNavbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto"/>
                        <Nav>
                            <NavbarLink to="/" title="Home" onClick={ collapse }/>
                            <NavbarDropdown title="Projects" active={["/mobler", "/paint"]} onClick={ collapse }>
                                <NavbarDropdownLink to="/mobler" title="Möbler"/>
                                <NavbarDropdownLink to="/paint" title="P.A.I.N.T."/>
                            </NavbarDropdown>
                            <NavbarDropdown title="Side projects" active={["/ios/smithsolarmonitoring", "/ios/iswlichtkrant"]} onClick={ collapse }>
                                <NavbarDropdownLink to="/ios/smithsolarmonitoring" title="Smith Solar Monitoring"/>
                                <NavbarDropdownLink to="/ios/iswlichtkrant" title="ISW Lichtkrant"/>
                            </NavbarDropdown>
                        </Nav>
                    </RBNavbar.Collapse>
                </Container>
            </RBNavbar>

            <div className="d-none d-sm-block" style={{ height: "56px" }}/>
            <div className="d-block d-sm-none" style={{ height: "50px" }}/>
        </React.Fragment>
    );
}

export default React.memo(Navbar);

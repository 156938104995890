import React from 'react';
import Helmet from "../../components/Helmet";

function ISWLichtkrantPrivacyPolicy() {
    return (
        <React.Fragment>
            <Helmet
                title="ISW Lichtkrant Privacyverklaring"
                description=""
            />

            <div className="jumbotron jumbotron-fluid">
                <div className="container text-center">
                    <h1>ISW Lichtkrant</h1>
                    <h2>Privacyverklaring</h2>
                </div>
            </div>

            <div className="container mt-4">

                <p><b>Deze privacyverklaring geldt alleen voor de ISW Lichtkrant app voor iOS.</b></p>

                <p><b>Laatst bijgewerkt: 06-02-2019.</b></p>

                <p>Zandor Smith is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze
                    privacyverklaring.</p>

                <p>Contactgegevens:</p>

                <p>Zandor Smith <a href="mailto:info@zsinfo.nl">info@zsinfo.nl</a></p>

                <h2>Persoonsgegevens die wij verwerken</h2>
                <p>Zandor Smith verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u
                    deze zelf aan ons verstrekt.</p>

                <p>Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:</p>
                <ul>
                    <li>Apparaat type</li>
                    <li>iOS versie</li>
                    <li>App versie</li>
                    <li>Standaard lichtkrant</li>
                    <li>IP-adres</li>
                    <li>Manier waarop de geleverde service gebruikt wordt</li>
                    <li>Eventuele informatie verstrekt door u aan ons via de geleverde service</li>
                </ul>

                <h2>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h2>
                <p>Zandor Smith verwerkt uw persoonsgegevens voor de volgende doelen:</p>
                <ul>
                    <li>Het leveren van de service.</li>
                    <li>U te informeren over wijzigingen van onze diensten en producten</li>
                    <li>Verbeteren van de geleverde service.</li>
                    <li>Zandor Smith verwerkt ook persoonsgegevens als hier wettelijk toe verplicht is.</li>
                </ul>

                <h2>Geautomatiseerde besluitvorming</h2>
                <p>Zandor Smith neemt niet op basis van geautomatiseerde verwerkingen besluiten over zaken die
                    (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen
                    door computerprogramma&#39;s of -systemen, zonder dat daar een mens (bijvoorbeeld een persoon)
                    tussen zit.</p>

                <h2>Bewaartermijn</h2>
                <p>Zandor Smith bewaart uw persoonsgegevens zolang dat nodig is voor de doelen die we in deze verklaring
                    noemen of om te voldoen aan wettelijke (bewaar)verplichtingen.</p>

                <h2>Delen van persoonsgegevens met derden</h2>
                <p>Zandor Smith verkoopt uw gegevens niet aan derden en verstrekt deze uitsluitend indien dit nodig is
                    voor de uitvoering van onze overeenkomst met u of om te voldoen aan een wettelijke verplichting. Met
                    bedrijven die uw gegevens verwerken in onze opdracht, sluiten wij een bewerkersovereenkomst om te
                    zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van uw gegevens. Zandor Smith
                    blijft verantwoordelijk voor deze verwerkingen.</p>

                <h2>Cookies, of vergelijkbare technieken, die wij gebruiken</h2>
                <p>Zandor Smith gebruikt in de ISW Lichtkrant app geen cookies. Een cookie is een klein tekstbestand dat
                    bij het eerste bezoek aan deze website wordt opgeslagen op uw computer, tablet of smartphone.</p>

                <h2>Gegevens inzien, aanpassen of verwijderen </h2>
                <p>U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heeft
                    u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken
                    tegen de verwerking van uw persoonsgegevens door Zandor Smith en heeft u het recht op
                    gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de
                    persoonsgegevens die wij van u beschikken in een computerbestand naar u of een ander, door u
                    genoemde organisatie, te sturen.</p>

                <p>U kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens
                    of verzoek tot intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens
                    sturen naar <a href="mailto:info@zsinfo.nl" target="_blank"
                                   rel="noopener noreferrer">info@zsinfo.nl</a>.</p>

                <p>Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw
                    identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine
                    readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer
                    (BSN) zwart. Dit ter bescherming van uw privacy. We reageren zo snel mogelijk, maar binnen vier
                    weken, op uw verzoek.</p>

                <p>Zandor Smith wil u er tevens op wijzen dat u de mogelijkheid heeft om een klacht in te dienen bij de
                    nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: <a
                        href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons"
                        target="_blank" rel="noopener noreferrer">https://
                        <wbr/>
                        autoriteitpersoonsgegevens.nl/
                        <wbr/>
                        nl/contact-met-de-autoriteit-
                        <wbr/>
                        persoonsgegevens/tip-ons</a>.
                </p>

                <h2>Hoe wij persoonsgegevens beveiligen</h2>

                <p>Zandor Smith neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik,
                    verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als
                    u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik,
                    neem dan contact op met onze klantenservice of via <a href="mailto:info@zsinfo.nl" target="_blank"
                                                                          rel="noopener noreferrer">info@zsinfo.nl</a>.
                </p>

                <h2>Ontkenning</h2>

                <p>Zandor Smith is niet geassocieerd met ISW Hoogeland of ISW in het algemeen.</p>

                <p>ISW = Interconfessionele Scholengroep Westland</p>

            </div>
        </React.Fragment>
    );
}

export default React.memo(ISWLichtkrantPrivacyPolicy);

import React from "react";
import Helmet from "../../components/Helmet";

import "./../../scss/old-site.scss";

import appIcon from "./../../img/projects/ios/isw-lichtkrant/icon.svg";
import appStore from "./../../img/projects/ios/appstore.svg";

import screenshotMavo from "./../../img/projects/ios/isw-lichtkrant/screenshots/mavo.png";
import screenshotOnderbouw from "./../../img/projects/ios/isw-lichtkrant/screenshots/onderbouw.png";
import screenshotBovenbouw from "./../../img/projects/ios/isw-lichtkrant/screenshots/bovenbouw.png";
import screenshotTeachers from "./../../img/projects/ios/isw-lichtkrant/screenshots/docenten.png";

function ISWLichtkrant() {
    return (
        <React.Fragment>
            <Helmet
                title="ISW Lichtkrant"
                description="This is the mobile version of the ticker (or 'Lichtkrant' in Dutch) being used at ISW Hoogeland to provide announcements and schedule change information to students."
            />

            <div className="jumbotron jumbotron-fluid jumbotron-rotate jumbotron-top jumbotron-app-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <img className="ios-app-icon" src={appIcon} height="100px" style={{marginTop: "40px"}}
                                 alt="ISW Lichtkrant App Icon"/>
                            <h1 className="title title-app"><b>ISW Lichtkrant</b></h1>
                            <p className="text-white" style={{fontSize: "1.25rem"}}>
                                This is the mobile version of the ticker (or "Lichtkrant" in Dutch) being used at
                                ISW Hoogeland to provide announcements and schedule change information to students.
                                Note that the app is available in Dutch only.
                            </p>
                            <p className="text-white" style={{fontSize: "1.25rem"}}>
                                Available for <b>iPhone</b> and <b>iPad</b>.
                            </p>
                            <br/>
                            <a href="https://apps.apple.com/nl/app/isw-lichtkrant/id1047218435" target="_blank"
                               rel="noreferrer noopener">
                                <img className="appstore" src={appStore} alt="Available on the App Store"/>
                            </a>
                        </div>
                        <div className="col-md-4 hidden-sm hidden-xs">
                            <img src={screenshotBovenbouw} className="img-fluid" alt="ISW Lichtkrant screenshot"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="jumbotron jumbotron-fluid" style={{backgroundColor: "transparent"}}>
                <div className="container text-center">
                    <p style={{fontSize: "1.25rem"}}>
                        <a href="https://hoogeland.isw.info" target="_blank" rel="noreferrer noopener">ISW
                            Hoogeland</a>{" "}
                        is the highschool I went to in the past and they had a ticker system that showed
                        announcements and schedule changes on TV screens throughout the building. Additionally,
                        they had a <a href="http://iswlichtkrant.nl" target="_blank"
                                      rel="noopener noreferrer">website</a>{" "}
                        where this was displayed too for easy access.
                    </p>
                    <p style={{fontSize: "1.25rem"}}>
                        They had an Android app too, but not for iOS and thus I decided to write the iOS version. A
                        backend server reads the HTML DOM from the school's website periodically and stores this in
                        a database after having adjusted the DOM slightly to optimize it for iOS and the device's
                        high resolution screen.
                    </p>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-4 col-sm-6 mb-5">
                        <img src={screenshotMavo} className="img-fluid mb-3" alt="Mavo Lichtkrant"/>
                        <h3>Mavo Lichtkrant</h3>
                        <p>Available by default</p>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-5">
                        <img src={screenshotOnderbouw} className="img-fluid mb-3" alt="Onderbouw Lichtkrant"/>
                        <h3>Onderbouw Lichtkrant</h3>
                        <p>Available by default</p>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-5">
                        <img src={screenshotBovenbouw} className="img-fluid mb-3" alt="Bovenbouw Lichtkrant"/>
                        <h3>Bovenbouw Lichtkrant</h3>
                        <p>Available by default</p>
                    </div>
                    <div className="col-md-12 col-sm-6 mb-5">
                        <div className="row">
                            <div className="col-md-4 hidden-sm hidden-xs"/>
                            <div className="col-md-4">
                                <img src={screenshotTeachers} className="img-fluid mb-3" alt="Teachers Lichtkrant"/>
                                <h3>Teachers Lichtkrant</h3>
                                <p>In app purchase or activation</p>
                            </div>
                            <div className="col-md-4 hidden-sm hidden-xs"/>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(ISWLichtkrant);

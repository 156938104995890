import React from 'react';
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";

import "./scss/main.scss";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import CookieConsentBanner from "./components/CookieConsentBanner";
import ScrollToTop from "./hooks/ScrollToTop";
import useTracking from "./hooks/TrackingHook";

import Home from "./pages/Home";
import Mobler from "./pages/games/Mobler";
import PAINT from "./pages/games/PAINT";
import SmithSolarMonitoring from "./pages/ios/SmithSolarMonitoring";
import ISWLichtkrantPrivacyPolicy from "./pages/ios/ISWLichtkrantPrivacyPolicy";
import ISWLichtkrant from "./pages/ios/ISWLichtkrant";
import Page404 from "./pages/Page404";

function App() {
    useTracking("UA-69123742-1");
    return (
        <React.Fragment>

            <Navbar/>

            <Switch>

                <Route path="/404">
                    <ScrollToTop/>
                    <Page404/>
                </Route>

                <Route path="/mobler">
                    <ScrollToTop/>
                    <Mobler/>
                </Route>

                <Route path="/paint">
                    <ScrollToTop/>
                    <PAINT/>
                </Route>

                <Route path="/smithsolarmonitoring">
                    <Redirect to="/ios/smithsolarmonitoring"/>
                </Route>
                <Route path="/ios/smithsolarmonitoring">
                    <ScrollToTop/>
                    <SmithSolarMonitoring/>
                </Route>

                <Route path="/iswlichtkrant" exact>
                    <Redirect to="/ios/iswlichtkrant"/>
                </Route>
                <Route path="/ios/iswlichtkrant" exact>
                    <ScrollToTop/>
                    <ISWLichtkrant/>
                </Route>

                <Route path="/iswlichtkrant/privacyverklaring" exact>
                    <Redirect to="/ios/iswlichtkrant/privacyverklaring"/>
                </Route>
                <Route path="/ios/iswlichtkrant/privacyverklaring" exact>
                    <ScrollToTop/>
                    <ISWLichtkrantPrivacyPolicy/>
                </Route>

                <Route path="/">
                    <ScrollToTop/>
                    <Home/>
                </Route>

            </Switch>

            <CookieConsentBanner/>

            <Footer/>

        </React.Fragment>
    );
}

export default App;

import React from "react";

class ProjectDetailsTableRow extends React.Component {
    render() {
        return (
            <tr>
                <th scope="row" style={{ verticalAlign: "middle" }}>{ this.props.title }</th>
                <td style={{ verticalAlign: "middle" }}>{ this.props.children }</td>
            </tr>
        );
    }
}

export default React.memo(ProjectDetailsTableRow);

import React from 'react';

import cpp from '../../img/capabilities/languages/cpp.svg';
import php from '../../img/capabilities/languages/php.svg';
import javascript from '../../img/capabilities/languages/javascript.svg';
import react from '../../img/capabilities/languages/react.svg';
import python from '../../img/capabilities/languages/python.svg';
import html5 from '../../img/capabilities/languages/html5.svg';
import css from '../../img/capabilities/languages/css.svg';
import swift from '../../img/capabilities/languages/swift.svg';
import sass from '../../img/capabilities/languages/sass.svg';
import java from '../../img/capabilities/languages/java.svg';

import git from '../../img/capabilities/production/git.svg';
import gitlab from '../../img/capabilities/production/gitlab.svg';
import github from '../../img/capabilities/production/github.svg';
import perforce from '../../img/capabilities/production/perforce.svg';
import jira from '../../img/capabilities/production/jira.svg';
import trello from '../../img/capabilities/production/trello.svg';
import azuredevops from '../../img/capabilities/production/azuredevops.svg';

import unrealengine from '../../img/capabilities/development/unrealengine.svg';
import visualstudio from '../../img/capabilities/development/visualstudio.svg';
import xcode from '../../img/capabilities/development/xcode.png';
import phpstorm from '../../img/capabilities/development/phpstorm.svg';
import jenkins from '../../img/capabilities/production/jenkins.svg';

import windows from '../../img/capabilities/platforms/windows.svg';
import macos from '../../img/capabilities/platforms/macos.svg';
import ios from '../../img/capabilities/platforms/ios.svg';
import steam from '../../img/capabilities/platforms/steam.svg';
import playstation4 from '../../img/capabilities/platforms/playstation4.svg';
import raspberrypi from '../../img/capabilities/platforms/raspberrypi.svg';
import xboxone from '../../img/capabilities/platforms/xboxone.svg';
import debian from '../../img/capabilities/platforms/debian.svg';
import nintendoswitch from '../../img/capabilities/platforms/nintendoswitch.svg';

function Capability(props) {
    return (
        <div className="col-4 col-sm-6 col-md-6 col-lg-4 text-center mt-3">
            <div className="ml-xl-2 mr-xl-2 mb-2">
                <img src={props.image} alt={props.title} style={{width: "100%"}}/>
            </div>
            <h6>{props.title}</h6>
        </div>
    )
}

function CapabilitiesCard({ title, children }) {
    return (
        <div className="card card-glass">
            <div className="card-body">
                <h5 className="card-title">{ title }</h5>
                <div className="row">
                    { children }
                </div>
            </div>
        </div>
    )
}

function CapabilitiesJumbotron() {
    return (
        <div className="jumbotron jumbotron-fluid jumbotron-clear bg-pattern-light">
            <div className="container">
                <h1>Capabilities</h1>
                <div className="card-deck">
                    <CapabilitiesCard title="Languages">
                        <Capability image={ cpp } title="C++"/>
                        <Capability image={ swift } title="Swift"/>
                        <Capability image={ react } title="React"/>
                        <Capability image={ javascript } title="JavaScript"/>
                        <Capability image={ html5 } title="HTML5"/>
                        <Capability image={ css } title="CSS"/>
                        <Capability image={ sass } title="Sass"/>
                        <Capability image={ php } title="PHP"/>
                        <Capability image={ python } title="Python"/>
                        <Capability image={ java } title="Java"/>
                    </CapabilitiesCard>
                    <CapabilitiesCard title="Production Tools">
                        <Capability image={ git } title="Git"/>
                        <Capability image={ gitlab } title="GitLab"/>
                        <Capability image={ github } title="GitHub"/>
                        <Capability image={ perforce } title="Perforce"/>
                        <Capability image={ jira } title="JIRA"/>
                        <Capability image={ trello } title="Trello"/>
                        <Capability image={ azuredevops } title="Azure DevOps"/>
                    </CapabilitiesCard>
                    <CapabilitiesCard title="Development Tools">
                        <Capability image={ unrealengine } title="Unreal Engine"/>
                        <Capability image={ visualstudio } title="Visual Studio"/>
                        <Capability image={ xcode } title="Xcode"/>
                        <Capability image={ phpstorm } title="PhpStorm"/>
                        <Capability image={ gitlab } title="GitLab CI"/>
                        <Capability image={ jenkins } title="Jenkins"/>
                    </CapabilitiesCard>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-4 mt-sm-3">
                        <CapabilitiesCard title="Platforms">
                            <Capability image={ windows } title="Windows"/>
                            <Capability image={ macos } title="macOS"/>
                            <Capability image={ ios } title="iOS"/>
                            <Capability image={ steam } title="Steam"/>
                            <Capability image={ playstation4 } title="PlayStation 4"/>
                            <Capability image={ nintendoswitch } title="Nintendo Switch"/>
                            <Capability image={ xboxone } title="Xbox One"/>
                            <Capability image={ debian } title="Debian Distros"/>
                            <Capability image={ raspberrypi } title="Raspberry Pi"/>
                        </CapabilitiesCard>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(CapabilitiesJumbotron);

import React from "react";
import Helmet from "../components/Helmet";

import TopJumbotron from "../components/home/TopJumbotron";
import AboutMeJumbotron from "../components/home/AboutMeJumbotron";
import ProjectsJumbotron from "../components/home/ProjectsJumbotron";
import CapabilitiesJumbotron from "../components/home/CapabilitiesJumbotron";
import ContactJumbotron from "../components/home/ContactJumbotron";

import meImage from './../img/me.jpg';

function Home() {
    return (
        <React.Fragment>

            <Helmet
                description="Hi, my name is Zandor Smith and I am 20 years old. I am currently studying Game Programming at Breda University of Applied Sciences. I am looking for an internship from February 2021."
                image={meImage}
            />

            <TopJumbotron/>
            <AboutMeJumbotron/>
            <ProjectsJumbotron/>
            <CapabilitiesJumbotron/>
            <ContactJumbotron/>

        </React.Fragment>
    );
}

export default React.memo(Home);

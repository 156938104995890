import React from "react";

import steam from "./../img/platforms/steam.png";
import nintendoSwitch from "./../img/platforms/nintendo-switch.png";
import xboxone from "./../img/platforms/xbox-one.png";
import playstation4 from "./../img/platforms/playstation4.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function Platform(props) {
    let image = "";
    if(props.steam) {
        image = steam;
    } else if(props.nintendoSwitch) {
        image = nintendoSwitch;
    } else if(props.xboxone) {
        image = xboxone;
    } else if(props.ps4) {
        image = playstation4;
    }
    return (
        <a href={ props.link } target="_blank" rel="noopener noreferrer">
            <div className="platform mb-3 ml-3 mr-3">
                <img src={ image } height={ 95 / 2 } alt="Platform"/>
                <div className={ (props.soon || props.support) ? "platform-soon" : "d-none" }>
                    { props.soon ? (
                        <b>Soon</b>
                    ) : (props.support ? (
                        <OverlayTrigger placement="top" overlay={
                            <Tooltip>
                                Game supports platform. No release.
                            </Tooltip>
                        }>
                            <div>
                                <b>Supported</b>
                            </div>
                        </OverlayTrigger>
                    ) : "")}
                </div>
            </div>
        </a>
    )
}

export default React.memo(Platform);

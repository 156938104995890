import React from "react";

function PlatformsRow(props) {
    return (
        <div className="d-flex justify-content-center">
            <div className="d-block d-sm-flex justify-content-center">
                { props.children }
            </div>
        </div>
    )
}

export default React.memo(PlatformsRow);
